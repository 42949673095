.c-icon {
  display: inline-block;
  color: inherit;
  text-align: center;
  fill: currentColor;
}

.c-icon:not(.c-icon-c-s):not(.c-icon-custom-size) {
  width: 1rem;
  height: 1rem;
  font-size: 1rem;
}

.c-icon:not(.c-icon-c-s):not(.c-icon-custom-size).c-icon-2xl {
  width: 2rem;
  height: 2rem;
  font-size: 2rem;
}

.c-icon:not(.c-icon-c-s):not(.c-icon-custom-size).c-icon-3xl {
  width: 3rem;
  height: 3rem;
  font-size: 3rem;
}

.c-icon:not(.c-icon-c-s):not(.c-icon-custom-size).c-icon-4xl {
  width: 4rem;
  height: 4rem;
  font-size: 4rem;
}

.c-icon:not(.c-icon-c-s):not(.c-icon-custom-size).c-icon-5xl {
  width: 5rem;
  height: 5rem;
  font-size: 5rem;
}

.c-icon:not(.c-icon-c-s):not(.c-icon-custom-size).c-icon-6xl {
  width: 6rem;
  height: 6rem;
  font-size: 6rem;
}

.c-icon:not(.c-icon-c-s):not(.c-icon-custom-size).c-icon-7xl {
  width: 7rem;
  height: 7rem;
  font-size: 7rem;
}

.c-icon:not(.c-icon-c-s):not(.c-icon-custom-size).c-icon-8xl {
  width: 8rem;
  height: 8rem;
  font-size: 8rem;
}

.c-icon:not(.c-icon-c-s):not(.c-icon-custom-size).c-icon-9xl {
  width: 9rem;
  height: 9rem;
  font-size: 9rem;
}

.c-icon:not(.c-icon-c-s):not(.c-icon-custom-size).c-icon-xl {
  width: 1.5rem;
  height: 1.5rem;
  font-size: 1.5rem;
}

.c-icon:not(.c-icon-c-s):not(.c-icon-custom-size).c-icon-lg {
  width: 1.25rem;
  height: 1.25rem;
  font-size: 1.25rem;
}

.c-icon:not(.c-icon-c-s):not(.c-icon-custom-size).c-icon-sm {
  width: 0.875rem;
  height: 0.875rem;
  font-size: 0.875rem;
}